import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 400;
const isTablet = width < 1024 && width > 400;

const scaleFont = (size) => {
	const scale = width / 320; // Base scale on standard width (e.g., iPhone SE width)
	const newSize = size * scale;
	return Math.round(newSize);
};

const styles = StyleSheet.create({
	body: {
		flex: 1, // Use flex to make it cover the entire viewport
		backgroundColor: "rgb(15, 15, 15)",
		height: "100%",
		maxWidth: '100vw', 
		overflow: 'hidden'
	},
	container: {
		flex: 1,
		justifyContent: "left",
		paddingHorizontal: isSmallDevice ? 25 : isTablet ? 25 : 150,
		backgroundColor: "rgb(15, 15, 15)",
		marginTop: isSmallDevice ? -15 : isTablet ? 15 : 25,
		marginBottom: isSmallDevice ? 50 : isTablet ? 50 : 100,
	},
	title: {
		fontFamily: "Montserrat",
		fontSize: isSmallDevice ? 40 : isTablet ? 60 : 80,
		color: "#ffffff",
		fontWeight: "bold",
		textAlign: "center",
	},
	subtitle: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 15 : isTablet ? 30 : 60,
		color: "#ffffff",
		textAlign: "center",
	},
	tagline: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 14 : isTablet ? 20 : 45,
		color: "#ffffff",
		marginBottom: 20,
		textAlign: "center",
	},
	description: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 14 : isTablet ? 20 : 40,
		color: "#ffffff",
		lineHeight: isSmallDevice ? 15 : isTablet ? 20 : 38,
		textAlign: "left",
	},
	boldText: {
		fontWeight: "bold",
		textAlign: "center",
	},
	separator: {
		backgroundColor: "#ffffff",
		height: isSmallDevice ? 3 : isTablet ? 8 : 8,
		borderRadius: 500,
		marginVertical: isSmallDevice ? 30 : isTablet ? 50 : 85,
		width: "100%",
	},
	bottomText: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 14 : isTablet ? 20 : 40,
		color: "#ffffff",
		lineHeight: isSmallDevice ? 15 : isTablet ? 20 : 38,
		textAlign: "center",
	},
	button: {
		flex: 1,
		backgroundColor: "#ffffff",
		padding: isSmallDevice ? 5 : isTablet ? 5 : 5,
		borderRadius: 15,
		marginTop: isSmallDevice ? 10 : isTablet ? 20 : 20,
		width: isSmallDevice ? 150 : isTablet ? 250 : 475,
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center",
	},
	buttonText: {
		color: "rgb(15, 15, 15)",
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 18 : isTablet ? 25 : 34,
		textAlign: "center",
		top: isSmallDevice ? 3 : isTablet ? 5 : 5,
	},
	buttonContainer: {
		justifyContent: "center",
		alignItems: "center",
		top: isSmallDevice ? -5 : isTablet ? -20 : -25,
	},
});

export default styles;
