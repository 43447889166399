import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 400;
const isTablet = width < 1024 && width > 400;

const scaleFont = (size) => {
	const scale = width / 320; // Base scale on standard width (e.g., iPhone SE width)
	const newSize = size * scale;
	return Math.round(newSize);
};
const baseHeaderStyle = {
	fontFamily: "Montserrat",
	fontSize: isSmallDevice ? 34 : isTablet ? 68 : 125, // Adjust according to your device's resolution
	color: "#fff",
	height: "100%",
	textAlign: "center"
};

const baseSubHeaderStyle = {
	fontFamily: "Montserrat",
	fontSize: isSmallDevice ? 15 : isTablet ? 20 : 40, // Adjust according to your device's resolution
	color: "#fff",
	height: "100%",
	textAlign: "center"
};

const styles = StyleSheet.create({
	body: {
		flex: 1, // Use flex to make it cover the entire viewport
		backgroundColor: "rgb(15, 15, 15)",
		position: "relative",
		height: "100vh",
		maxWidth: '100vw',
		overflow: 'hidden' // Prevent scrolling
	},
	videoBackground: {
		position: "fixed",
		top: 0,
		left: 0,
		width: "100vw",
		height: "100vh",
		zIndex: -1, // Make sure it is behind other content
		objectFit: "cover",
	},

	logo: {
		width: 110,
		height: 110, // Change the height to 100% to fit within the navbar
		transform: [{ scale: 0.75 }],
	},
	container: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,
	},
	headerContainer: {
		width: "100%",
		alignItems: "center",
		marginBottom: 30,
		marginTop: 250,
	},
	header: {
		...baseHeaderStyle,
		textShadowColor: "#fff",
		textShadowOffset: { width: 0, height: 2 },
		textShadowOpacity: 0.05,
		textShadowRadius: 3.84,
	},
	subHeader: {
		fontFamily: "Montserrat",
		fontSize: isSmallDevice ? 15 : isTablet ? 20 : 40, // Adjust according to your device's resolution
		color: "#fff",
		height: "100%",
		textAlign: "center",
		fontStyle: "italic",
		textShadowColor: "#fff",
		textShadowOffset: { width: 0, height: 2 },
		textShadowOpacity: 0.5,
		textShadowRadius: 3.84,
	},
	// Add an absolute positioning style
	headerAbsolute: {
		position: "absolute",
		top: 0,
		left: 0,
	},
	headerGlitch1: {
		...baseHeaderStyle,
		position: "absolute",
		color: "#67f3da",
	},
	headerGlitch2: {
		...baseHeaderStyle,
		position: "absolute",
		color: "#f16f6f",
	},
	subHeaderGlitch1: {
		...baseSubHeaderStyle,
		position: "absolute",
		color: "#67f3da",
	},
	subHeaderGlitch2: {
		...baseSubHeaderStyle,
		position: "absolute",
		color: "#f16f6f",
	},
	buttonTextGlitch1: {
		position: "absolute",
		color: "#67f3da",
	},
	buttonTextGlitch2: {
		position: "absolute",
		color: "#f16f6f",
	},
	buttonContainer: {
		flex: 1,
		flexDirection: "column",
		alignItems: "center",
	},
	scheduler: {
		marginVertical: 10,
		width: 340,
		height: 75,
		borderRadius: 25,
		justifyContent: "center",
		alignItems: "center",
	},
	contact: {
		marginVertical: 10,
		width: 340,
		height: 75,
		borderRadius: 25,
		justifyContent: "center",
		alignItems: "center",
	},
	button: {
		marginHorizontal: 75,
	},
	buttonText: {
		fontFamily: "Khmer MN",
		fontSize: 35,
		color: "#ffffff",
	},
	animButton: {
		display: isSmallDevice ? "none" : isTablet ? "none" : "block",
	}
});

export default styles;