import React, { useState } from "react";
import { View, TextInput, Text, Pressable, Picker, StyleSheet } from "react-native";
import axios from "axios";
import Navbar from "../Widgets/NavBar";
import styles from "../Styles/ContactStyles";
import { SERVER_URL, DEV_SERVER_URL } from "@env";

const Contact = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [clientType, setClientType] = useState("new");
	const [feedbackMessage, setFeedbackMessage] = useState("");
	const [isSuccess, setIsSuccess] = useState(false); // New state for successful submission
	const [isSubmitted, setIsSubmitted] = useState(false); // New state to determine if the form has been submitted
	const [touchedFields, setTouchedFields] = useState({
		firstName: false,
		lastName: false,
		email: false,
		phone: false,
		subject: false,
		message: false,
	});
	const [isLoading, setIsLoading] = useState(false); // New state for loading

	const isValidEmail = (email) => {
		const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		return emailPattern.test(email);
	};

	const isValidPhone = (phone) => {
		const phonePattern = /^\d{10,15}$/; // Standard phone number pattern (10 to 15 digits)
		return phonePattern.test(phone.replace(/\D/g, ''));
	};

	const isValidName = (name) => {
		const namePattern = /^[A-Za-z\s'-]+$/; // Standard name pattern (letters, spaces, apostrophes, hyphens)
		return namePattern.test(name);
	};

	const isValidMessage = (message) => {
		const messagePattern = /^[A-Za-z0-9\s.,'?!-]+$/; // Standard message pattern (letters, numbers, spaces, punctuation)
		return messagePattern.test(message);
	};

	const handleNameChange = (setter) => (value) => {
		if (/^[A-Za-z\s'-]*$/.test(value)) {
			setter(value);
		}
	};

	const handlePhoneChange = (value) => {
		// Allow only digits and format as XXX-XXX-XXXX
		const cleaned = value.replace(/\D/g, '');
		let formatted = cleaned;
		if (cleaned.length > 3 && cleaned.length <= 6) {
			formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
		} else if (cleaned.length > 6) {
			formatted = `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
		}
		if (cleaned.length <= 10) {
			setPhone(formatted);
		}
	};

	const handleEmailChange = (value) => {
		if (/^[A-Z0-9._%+-@]*$/i.test(value)) {
			setEmail(value);
		}
	};

	const handleMessageChange = (value) => {
		if (/^[A-Za-z0-9\s.,'?!-]*$/.test(value)) {
			setMessage(value);
		}
	};

	const handleSubmit = async () => {
		setIsLoading(true); // Set loading to true when submission starts
		let isValid = true;
		let errors = [];

		if (!isValidName(firstName) || !isValidName(lastName)) {
			isValid = false;
			errors.push("Please enter a valid first and last name.");
		}
		if (!isValidEmail(email)) {
			isValid = false;
			errors.push("Please enter a valid email.");
		}
		const cleanedPhone = phone.replace(/\D/g, '');
		if (cleanedPhone && (cleanedPhone.length < 10 || cleanedPhone.length > 15)) {
			isValid = false;
			errors.push("Phone number should contain 10 to 15 digits only.");
		}
		if (!subject.trim()) {
			isValid = false;
			errors.push("Subject is required.");
		}
		if (!isValidMessage(message)) {
			isValid = false;
			errors.push("Please enter a valid message.");
		}
		setFeedbackMessage(errors.join(" "));

		if (isValid) {
			try {
				const serverUrl = __DEV__ ? DEV_SERVER_URL : SERVER_URL; // Use dev server URL in development
				const response = await axios.post(`${serverUrl}/send-email`, {
					firstName: firstName,
					lastName: lastName,
					email: email,
					phone: phone,
					subject: subject,
					message: message,
					// clientType: clientType,
				});

				if (response.status === 200) {
					console.log("Email sent successfully.");
					setIsSuccess(true);
					setIsSubmitted(true); // Set to true on successful submission
					setFeedbackMessage("Thank you, we've received your message!");
				} else {
					setIsSuccess(false);
					setFeedbackMessage("Failed to send email. Please try again.");
				}
			} catch (error) {
				console.error("There was an error sending the email.", error);
				setIsSuccess(false);
				setFeedbackMessage("Failed to send email. Please try again later.");
			}
		}
		setIsLoading(false); // Set loading to false when submission ends
	};

	return (
		<View style={styles.body}>
			<View style={{ position: "sticky", top: 0, zIndex: 1000 }}>
				<Navbar />
			</View>
			<View style={styles.container}>
				<Text style={styles.header}>Drop us a line</Text>

				{!isSubmitted ? (
					<>
						{feedbackMessage && <Text style={isSuccess ? styles.successMessage : styles.errorMessage}>{feedbackMessage}</Text>}
						<View style={styles.inputRow}>
							<TextInput
								placeholder="* First Name"
								placeholderTextColor="rgba(255,255,255,0.5)"
								value={firstName}
								onChangeText={handleNameChange(setFirstName)}
								onBlur={() => setTouchedFields((prev) => ({ ...prev, firstName: true }))}
								style={[styles.halfInput, touchedFields.firstName && !isValidName(firstName) && { borderColor: "red" }]}
							/>
							<TextInput
								placeholder="* Last Name"
								placeholderTextColor="rgba(255,255,255,0.5)"
								value={lastName}
								onChangeText={handleNameChange(setLastName)}
								onBlur={() => setTouchedFields((prev) => ({ ...prev, lastName: true }))}
								style={[styles.halfInput, touchedFields.lastName && !isValidName(lastName) && { borderColor: "red" }]}
							/>
						</View>
						<TextInput
							placeholder="* Email"
							placeholderTextColor="rgba(255,255,255,0.5)"
							value={email}
							onChangeText={handleEmailChange}
							onBlur={() => setTouchedFields((prev) => ({ ...prev, email: true }))}
							style={[styles.input, touchedFields.email && !isValidEmail(email) && { borderColor: "red" }]}
						/>
						<TextInput
							placeholder="Phone"
							placeholderTextColor="rgba(255,255,255,0.5)"
							value={phone}
							onChangeText={handlePhoneChange}
							keyboardType="numeric"
							onBlur={() => setTouchedFields((prev) => ({ ...prev, phone: true }))}
							style={[styles.input, touchedFields.phone && phone && phone.replace(/\D/g, '').length !== 10 && { borderColor: "red" }]}
						/>
						<TextInput
							placeholder="* Subject"
							placeholderTextColor="rgba(255,255,255,0.5)"
							value={subject}
							onChangeText={setSubject}
							onBlur={() => setTouchedFields((prev) => ({ ...prev, subject: true }))}
							style={[styles.input, touchedFields.subject && !subject.trim() && { borderColor: "red" }]}
						/>
						<TextInput
							placeholder="* Message"
							placeholderTextColor="rgba(255,255,255,0.5)"
							value={message}
							onChangeText={handleMessageChange}
							onBlur={() => setTouchedFields((prev) => ({ ...prev, message: true }))}
							style={[styles.input, touchedFields.message && !isValidMessage(message) && { borderColor: "red" }]}
							multiline={true}
							numberOfLines={10}
							autoGrow={true}
						/>

						<Pressable disabled={isLoading || isSuccess} style={styles.button} onPress={handleSubmit}>
							<Text style={styles.buttonText}>{isLoading ? "Sending..." : isSuccess ? "Thank you!" : "Send"}</Text>
						</Pressable>
					</>
				) : (
					<Text style={{ color: "green", marginBottom: 10, fontSize: 30, fontFamily: "Khmer MN" }}>Thank you! One of our team members will be in touch soon!</Text>
				)}
			</View>
		</View>
	);
};

export default Contact;