import React from "react";
import { View, Text, Pressable } from "react-native";
import Navbar from "../Widgets/NavBar";
import { useNavigation } from "@react-navigation/native";
import styles from "../Styles/AboutStyles";

const About = () => {
	const navigation = useNavigation();

	return (
		<div style={styles.body}>
			<View style={{ position: "sticky", top: 0, zIndex: 1000 }}>
				<Navbar />
			</View>
			<View style={styles.container}>
				<Text style={styles.title}>Who are we?</Text>
				<Text style={styles.tagline}>We Power Businesses of the Future, Today.</Text>
				<Text style={styles.description}>
					Based out of sunny Santa Monica, our team is not just your run-of-the-mill software development firm. We're a small, dedicated team of designers, developers, and entrepreneurs with an unwavering focus & dedication to our clients' success.{"\n\n"}
					Our philosophy is simple:{"\n\n"}
					<Text style={styles.boldText}>When you succeed, we do too.</Text> {"\n\n"}
					In the rapidly evolving tech landscape, we pride ourselves on our flexibility and commitment to innovation. As your partner, we're dedicated to designing solutions that aren't just effective for your business today but power your business's future. {"\n\n"}
					We understand the unique challenges and opportunities your business faces, because we take the time to understand you. Our client-centric approach ensures tailored solutions that go well beyond merely meeting expectations, but rather setting new ones for what you and your team can achieve. {"\n\n"}
					Join us in building the businesses of tomorrow, today.
				</Text>
				<View style={styles.separator}></View> {/* Separator */}
				<Text style={styles.title}>Who are you?</Text>
				<Text style={styles.tagline}>You lead the charge.</Text>
				<Text style={styles.description}>
					Our clients are our partners. You share our drive, you put people first, you think outside of the box, and you have a passion to innovate and evolve. Good enough isn't good enough and settling isn't in your vocabulary.{"\n\n"}
					Our ideal clients understand the value of innovation, have a deep desire to redefine what's possible, and seek to shatter the status quo. You want to change the game. You want to stir the pot.{"\n\n"}
					<Text style={styles.boldText}>You want to make noise.</Text> {"\n\n"}
					If you're committed to the growth of your business and understand the impact of strategic technology solutions, we want to hear from you. Let's build the future, today.{"\n\n"}
					{"\n\n"}
				</Text>
				<View style={styles.buttonContainer}>
					<Text style={styles.bottomText}>Ready to make some noise?</Text>
					<Pressable style={styles.button}>
						<Text style={styles.buttonText} onPress={() => navigation.navigate("Contact")}>
							Let's Talk
						</Text>
					</Pressable>
				</View>
			</View>
		</div>
	);
};

export default About;
