import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
 body: {
		flex: 1, // Use flex to make it cover the entire viewport
		backgroundColor: "rgb(15, 15, 15)",
  margin: 10
	},
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		padding: 20,
  backgroundColor: "black",
	},
	title: {
		fontFamily: "Montserrat",
		fontSize: 50,
		color: "#ffffff",
		fontWeight: "bold",
		marginBottom: 20,
	},
	subtitle: {
  fontFamily: "Khmer MN",
		fontSize: 40,
		color: "#ffffff",
		marginBottom: 20,
		textAlign: "center",
	},
	body: {
		fontFamily: "Khmer MN",
		fontSize: 35,
		color: "#ffffff",
		lineHeight: 24,
		textAlign: "center",
	},
});

export default styles;
