import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 400;
const isTablet = width < 1024 && width > 400;

console.warn(width);

const scaleFont = (size) => {
	const scale = width / 320; // Base scale on standard width (e.g., iPhone SE width)
	const newSize = size * scale;
	return Math.round(newSize);
};

const styles = StyleSheet.create({
	body: {
		flex: 1, // Use flex to make it cover the entire viewport
		backgroundColor: "rgb(15, 15, 15)",
		height: "100%",
		maxWidth: "100vw",
		overflow: "hidden",
	},
	container: {
		flex: 1,
		justifyContent: "left",
		backgroundColor: "rgb(15, 15, 15)",
		paddingHorizontal: isSmallDevice ? 25 : isTablet ? 25 : 150,
		marginTop: 25,
		marginBottom: 100,
		width: "100%",
		height: "100%",
	},
	row: {
		flex: 1,
		flexDirection: "row",
		paddingTop: 25,
	},
	header: {
		flexDirection: isSmallDevice || isTablet ? "column" : "row",
		alignItems: isSmallDevice || isTablet ? "center" : "flex-start",
		justifyContent: "center",
		width: "100%",
	},
	subheader: {
		flexDirection: "column",
		alignItems: "flex-start", // Align items to the start of the container
		width: "75%",
	},
	logo: {
		width: isSmallDevice ? 100 : isTablet ? 200 : 500,
		height: isSmallDevice ? 100 : isTablet ? 200 : 500,
		resizeMode: "contain",
		alignSelf: isSmallDevice || isTablet ? "center" : "flex-end", // Center the logo on small devices
		paddingTop: isSmallDevice || isTablet ? 150 : 0,
	},
	exImg: {
		width: "100%", // Set a fixed height for your logo
		resizeMode: "contain",
		marginRight: "25px", // Push logo to the right
		marginTop: "10px",
		marginBottom: "10px",
		paddingHorizontal: 25,
		paddingVertical: 50,
		float: "center",
	},
	exImgL: {
		width: "50%", // Set a fixed height for your logo
		resizeMode: "contain",
		marginRight: "25px", // Push logo to the right
		marginTop: "10px",
		marginBottom: "10px",
		paddingHorizontal: 25,
		paddingVertical: 50,
		float: "left",
	},
	exImgR: {
		width: "50%", // Set a fixed width for your logo
		resizeMode: "contain",
		marginLeft: "25px", // Push logo to the right
		marginTop: "10px",
		marginBottom: "10px",
		paddingHorizontal: 25,
		paddingVertical: 50,
		float: "right",
	},
	title: {
		fontFamily: "Montserrat",
		fontSize: isSmallDevice ? 40 : isTablet ? 60 : 75,
		color: "#ffffff",
		fontWeight: "bold",
	},
	subtitle: {
		fontFamily: "Montserrat",
		fontSize: isSmallDevice ? 15 : isTablet ? 15 : 30,
		color: "#ffffff",
		lineHeight: isSmallDevice ? 20 : isTablet ? 24 : 38,
		textAlign: "left",
		paddingTop: 10,
	},
	dateline: {
		fontFamily: "Montserrat",
		fontSize: isSmallDevice ? 14 : isTablet ? 18 : 25,
		color: "#ffffff",
		lineHeight: isSmallDevice ? 20 : isTablet ? 24 : 38,
		textAlign: "left",
		paddingTop: 15,
	},
	changelogs: {
		fontFamily: "Montserrat",
		fontSize: isSmallDevice ? 12 : isTablet ? 14 : 20,
		color: "green",
		lineHeight: isSmallDevice ? 20 : isTablet ? 24 : 38,
		textAlign: "left",
		paddingTop: 15,
	},
	privacy: {
		fontFamily: "Montserrat",
		fontSize: isSmallDevice ? 12 : isTablet ? 14 : 20,
		color: "white",
		lineHeight: isSmallDevice ? 20 : isTablet ? 24 : 38,
		textAlign: "center",
		paddingTop: 15,
	},
	separator: {
		backgroundColor: "#ffffff",
		height: isSmallDevice ? 3 : isTablet ? 8 : 8,
		borderRadius: 500,
		marginVertical: isSmallDevice ? 30 : isTablet ? 50 : 85,
		width: "100%",
	},
	tagline: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 20 : isTablet ? 25 : 45,
		color: "#ffffff",
		marginBottom: 20,
		textAlign: "left",
	},
	description: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 20 : isTablet ? 30 : 40,
		color: "#ffffff",
		lineHeight: isSmallDevice ? 20 : isTablet ? 25 : 38,
		textAlign: "left",
		paddingBottom: 40,
	},
	blogText: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 20 : isTablet ? 15 : 34,
		color: "#ffffff",
		lineHeight: isSmallDevice ? 20 : isTablet ? 25 : 38,
		textAlign: "left",
		paddingHorizontal: isSmallDevice ? 15 : isTablet ? 20 : 30,
	},
	blogTextPR: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 20 : isTablet ? 15 : 38,
		lineHeight: isSmallDevice ? 1 : isTablet ? 1.1 : 1.4,
		color: "#ffffff",
		textAlign: "right",
	},
	blogTextPL: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 20 : isTablet ? 15 : 38,
		lineHeight: isSmallDevice ? 1 : isTablet ? 1.1 : 1.4,
		color: "#ffffff",
		textAlign: "left",
	},
	boldText: {
		fontWeight: "bold",
		textAlign: "right",
	},
	separator: {
		backgroundColor: "#ffffff",
		height: 8,
		borderRadius: 500,
		marginVertical: 85,
		width: 900,
	},
	bottomText: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 10 : isTablet ? 25 : 38,
		color: "#ffffff",
		lineHeight: 38,
		textAlign: "center",
	},
	button: {
		backgroundColor: "#ffffff",
		padding: scaleFont(10), // Scale padding as well
		borderRadius: 10,
		alignItems: "center",
		marginTop: 40,
		width: isSmallDevice ? "10%" : "80%", // Use percentage for width
		maxWidth: isSmallDevice ? "20%" : isTablet ? "20%" : "100%",
	},
	buttonText: {
		color: "rgb(15, 15, 15)",
		fontFamily: "Khmer MN",
		fontSize: scaleFont(16), // Use scaleFont here too
	},
	buttonContainer: {
		justifyContent: "center",
		alignItems: "center",
	},
	signUpButton: {
		backgroundColor: "green",
		color: "white",
		padding: isSmallDevice ? 5 : isTablet ? 5 : 10,
		borderRadius: 25,
		marginTop: isSmallDevice ? 15 : isTablet ? 20 : 25,
		minWidth: isSmallDevice ? 100 : isTablet ? 150 : 250,
		maxWidth: isSmallDevice ? "20%" : isTablet ? "20%" : "100%",
		height: 50,
		fontFamily: "Khmer MN",
		textAlign: "center",
	},
	signUpButtonText: {
		color: "#fff", // Adjust text color as needed
		textAlign: "center",
		fontSize: isSmallDevice ? 14 : isTablet ? 18 : 20,
	},
	coffeeButton: {
		backgroundColor: "purple", // Adjust button color as needed
		padding: 15,
		borderRadius: 5,
		marginTop: 15,
		marginLeft: 25,
		minWidth: isSmallDevice ? 100 : isTablet ? 150 : 250,
		maxWidth: isSmallDevice ? "20%" : isTablet ? "20%" : "100%",
	},
	emailCaptureContainer: {
		position: "fixed",
		flex: 1,
		top: 200,
		width: "75%",
		height: 550,
		marginHorizontal: 100,
		padding: 50,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgb(15, 15, 15)",
		zIndex: 99,
		borderRadius: 50,
		opacity: 0.99,
	},
	emailCaptureOverlay: {
		position: "fixed",
		flex: 1,
		top: 0,
		left: isSmallDevice ? 0 : isTablet ? undefined : undefined,
		width: 2000,
		height: 2000,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(255, 5, 255. 0.9)",
		zIndex: 99,
		opacity: 0.99,
	},
	emailCaptureText: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 25 : isTablet ? 30 : 38,
		color: "#ffffff",
		lineHeight: 38,
		textAlign: "center",
		paddingTop: 10,
		fontWeight: 900,
	},
	emailCaptureSubText: {
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 18 : isTablet ? 20 : 26,
		color: "#ffffff",
		lineHeight: isSmallDevice ? 25 : isTablet ? 30 : 38,
		textAlign: "center",
		paddingTop: 10,
	},
	input: {
		width: "100%",
		fontFamily: "Khmer MN",
		padding: 10,
		paddingHorizontal: isSmallDevice ? 18 : isTablet ? 20 : 25,
		margin: 10,
		backgroundColor: "#fff",
		borderRadius: 25,
		fontSize: 20,
	},
	emailSignUpButton: {
		backgroundColor: "green",
		color: "white",
		padding: 10,
		borderRadius: 5,
		marginTop: 10,
		minWidth: isSmallDevice ? 75 : isTablet ? 150 : 250,
		height: 50,
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 18 : isTablet ? 20 : 24,
		textAlign: "center",
		borderRadius: 50,
	},
	emailSignUpButtonText: {
		color: "white",
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 20 : isTablet ? 20 : 24,
		textAlign: "center",
	},
	closeButtonText: {
		color: "white",
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 20 : isTablet ? 20 : 24,
		textAlign: "center",
	},
	closeButtonEmail: {
		backgroundColor: "red",
		color: "white",
		padding: 10,
		borderRadius: 5,
		marginTop: 10,
		minWidth: isSmallDevice ? 75 : isTablet ? 150 : 250,
		height: 50,
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 20 : isTablet ? 20 : 24,
		textAlign: "center",
		borderRadius: 50,
	},
	button: {
		backgroundColor: "#007bff", // Button color
		paddingVertical: 10,
		paddingHorizontal: isSmallDevice ? 18 : isTablet ? 20 : 20,
		borderRadius: 5,
		marginTop: 10,
	},
	buttonText: {
		color: "#ffffff", // Text color
		fontSize: 16,
	},
	sourceLink: {
		marginTop: 20,
		marginBottom: 20,
		color: "#ffffff",
		fontFamily: "Montserrat",
		fontSize: 18,
		textAlign: "center",
	},
	sourceLinkText: {
		color: "blue",
		fontFamily: "Montserrat",
		fontSize: 18,
	},
	modalBackground: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
	},
	downloadLuaContainer: {
		backgroundColor: "rgb(15, 15, 15)",
		padding: 20,
		borderRadius: 52,
		width: "80%", // Adjust as needed
		maxHeight: "90%", // Adjust as needed
		alignItems: "center",
	},
	logoDL: {
		width: 250, // Set a fixed width for your logo
		height: 250, // Set a fixed height for your logo
		resizeMode: "contain",
	},
	downloadLuaTitle: {
		fontSize: 24,
		color: "#fff",
		marginBottom: 10,
		fontWeight: "bold",
		fontFamily: "Montserrat",
	},
	downloadLuaSubText: {
		fontSize: 20,
		color: "#fff",
		textAlign: "left",
		fontFamily: "Khmer MN",
	},
	downloadLuaText: {
		fontSize: 20,
		color: "#fff",
		marginBottom: 5,
		textAlign: "left",
		fontFamily: "Khmer MN",
	},
	downloadLuaTextVersion: {
		fontSize: 20,
		color: "green",
		textAlign: "left",
		fontFamily: "Khmer MN",
	},
	downloadButton: {
		backgroundColor: "green",
		color: "white",
		padding: 10,
		borderRadius: 5,
		marginTop: 25,
		minWidth: isSmallDevice ? 75 : isTablet ? 150 : 250,
		height: 50,
		fontFamily: "Khmer MN",
		fontSize: 24,
		textAlign: "center",
		textDecorationColor: "green",
	},
	downloadButtonText: {
		color: "white",
		fontFamily: "Khmer MN",
		fontSize: 24,
		textAlign: "center",
		textDecorationColor: "green",
	},
	getLuaButton: {
		backgroundColor: "green",
		padding: 15,
		borderRadius: 5,
		marginTop: 15,
	},
	closeButton: {
		backgroundColor: "red",
		color: "white",
		padding: 10,
		borderRadius: 5,
		marginTop: 10,
		minWidth: isSmallDevice ? 75 : isTablet ? 150 : 250,
		height: 50,
		fontFamily: "Khmer MN",
		fontSize: 24,
		textAlign: "center",
	},

	hamburgerMenu: {
		display: isSmallDevice || isTablet ? "flex" : "none", // Show only on mobile and tablet
		position: "absolute",
		top: 10,
		left: 10,
		zIndex: 100,
	},
});

export default styles;
