import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 400;
const isTablet = width < 1024 && width > 400;

const scaleFont = (size) => {
	const scale = width / 320; // Base scale on standard width (e.g., iPhone SE width)
	const newSize = size * scale;
	return Math.round(newSize);
};

const styles = StyleSheet.create({
	body: {
		flex: 1, // Use flex to make it cover the entire viewport
		backgroundColor: "rgb(15, 15, 15)",
		maxWidth: '100vw', 
		overflow: 'hidden'
	},
	container: {
		flex: 1,
		padding: 20,
		backgroundColor: "rgb(15, 15, 15)",
		paddingHorizontal: isSmallDevice ? 25 : isTablet ? 25 : 150,
	},
	header: {
		fontFamily: "Montserrat",
		fontSize: isSmallDevice ? 40 : isTablet ? 40 : 80,
		marginBottom: 40,
		color: "#ffffff",
		fontWeight: "bold",
	},
	inputRow: {
		flexDirection: "row",
		justifyContent: "space-between"
	},
	halfInput: {
		fontFamily: "Khmer",
		fontSize: 20,
		width: "48%", // take almost half the space, leaving a gap in between
		borderWidth: 1,
		borderColor: "#fff",
		borderRadius: 10,
		color: "#fff",
		padding: 10,
		marginBottom: 20,
	},
	input: {
		borderWidth: 1,
		fontFamily: "Khmer",
		fontSize: 20,
		borderColor: "#fff",
		borderRadius: 10,
		color: "#fff",
		padding: 10,
		marginBottom: 20,
	},
	picker: {
		fontFamily: "Khmer",
		fontSize: 20,
		color: "#fff",
		borderWidth: 1,
		borderColor: "#fff",
		backgroundColor: "transparent",
		borderRadius: 10,
		marginBottom: 20,
		padding: 5,
	},
	button: {
		backgroundColor: "#fff",
		padding: 15,
		borderRadius: 10,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 5,
		elevation: 5, // for Android shadow
	},
	buttonText: {
		color: "rgb(15, 15, 15)",
		fontFamily: "Khmer MN",
		fontSize: isSmallDevice ? 18 : isTablet ? 25 : 34,
		textAlign: "center",
		top: isSmallDevice ? 3 : isTablet ? 5 : 5,
	},
	successMessage: {
		color: "green",
		marginBottom: 10
},
errorMessage: {
		color: "red",
		marginBottom: 10
}
});

export default styles;
