// Screens/PrivacyPolicy.js
import React from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { useNavigation } from "@react-navigation/native";


const PrivacyPolicy = () => {
 const navigation = useNavigation();

	return (
		<ScrollView style={styles.container}>
			<Text style={styles.title}>Privacy Policy for Lua Browser Extension</Text>
			{/* Your privacy policy text here */}
			<Text style={styles.content}>
			<Text style={styles.currentVersionHeader}>Current Version: 1.1.0{"\n"}</Text>
    <Text style={styles.currentVersionHeader} onPress={() => navigation.navigate("Blog")}>Get Lua{"\n"}</Text>
				Thank you for using Lua!{"\n\n"}
				Our browser extension is designed to enhance your web browsing experience by providing contextual language translations and interactive language learning tools. This Privacy Policy outlines how we collect, use, store, and most importantly, protect your information.{"\n\n"}
				<Text style={styles.bold}>1. Information Collection and Use: </Text>
				Lua collects data to provide and improve the extension's functionality. We collect information in the following ways:
				<Text style={styles.bold}> User Preferences and Settings: </Text> We store your language selection, proficiency level, and choices regarding sharing of usage metrics. This data is used to personalize and improve your experience with Lua and will NEVER under any circumstance be sold, traded, or otherwise disclosed without your permission.
				<Text style={styles.bold}> Tab Information: </Text> We access the text information from your browser tabs to provide translation services across multiple tabs. This data, if you opt in to share your data with us, is only used to improve Lua's services for you.
				<Text style={styles.bold}> Active Tab Data: </Text> Lua requires access to the active tab to translate and modify the content of web pages you are viewing in real-time. Host Permissions: We interact with various websites to process and translate text, enhancing your browsing experience across different domains.{"\n"}
				<Text style={styles.bold}> Server Interactions: </Text> Our server handles translation services, metrics, and logs. This interaction is essential for the functionality of Lua and ensures user security and privacy.{"\n\n"}
				<Text style={styles.bold}>2. Data Storage:</Text>
				{"\n"} Lua stores data locally on your device to enhance performance and user experience. This includes storing your personalized settings and preferences. We implement robust security measures to protect your data from unauthorized access.{"\n\n"}
				<Text style={styles.bold}>3. Permission Justifications:</Text>
				{"\n"} Lua's functionalities require specific browser permissions, each justified as follows: ActiveTab: To translate text on the active tab. Tabs: Accessing tab information for seamless translation across multiple tabs. Storage: Storing user preferences and settings locally. Host Permissions: Interacting with various websites for translation purposes.{"\n\n"}
				<Text style={styles.bold}>4. Use of Remote Code:</Text>
				{"\n"} Lua does not use remote JavaScript, WebAssembly, or CSS that is not included in the extension's package. Our server is required for translation services and is integral to the privacy and security of the extension and our users.{"\n\n"}
				<Text style={styles.bold}>5. Data Sharing and Disclosure:</Text>
				{"\n"} We do not sell, trade, or rent users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.{"\n\n"}
				<Text style={styles.bold}>6. Changes to This Privacy Policy:</Text>
				{"\n"} Lua reserves the right to update or change our Privacy Policy at any time. We encourage users to frequently check this page for any changes. Your continued use of the service after any modifications to the Privacy Policy on this page will constitute your acknowledgment of the changes and your consent to abide by the modified Privacy Policy.{"\n\n"}
				<Text style={styles.bold}>7. Contact Information:</Text>
				{"\n"} If you have any questions about this Privacy Policy, please contact us at info@makenoisemedia.io or via our contact page.
			</Text>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
		backgroundColor: "rgb(15, 15, 15)",
	},
	title: {
		fontFamily: "Montserrat",
		fontSize: 28,
		fontWeight: "bold",
		marginBottom: 10,
		color: "white",
		paddingTop: 15,
		paddingHorizontal: 30,
	},
	content: {
		fontFamily: "Khmer MN",
		fontSize: 22,
		color: "#ffffff",
		lineHeight: 38,
		textAlign: "left",
		paddingHorizontal: 30,
	},
	bold: {
		fontWeight: 900,
	},
});

export default PrivacyPolicy;
