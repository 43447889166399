import React from 'react';
import Navigation from './Widgets/Navigation';

const App = () => {
  return (
    <Navigation />
  );
};

export default App;
