import React, { useRef, useState, useEffect } from "react";
import { Dimensions } from "react-native";
import { View, Pressable, Animated, Platform, Image, Text, Alert } from "react-native-web";
import { useNavigation } from "@react-navigation/native";
import styles from "../Styles/HomeStyles";
import Navbar from "../Widgets/NavBar";
import videoSource from "../assets/intro.mp4"

const { width, height } = Dimensions.get("window");
const isSmallDevice = width < 400;
const isTablet = width < 1024 && width > 400;

// Button component
const AnimatedButton = ({ label, pageName }) => {
	const navigation = useNavigation();
	const [isHovered, setIsHovered] = useState(false);
	const animValue = useRef(new Animated.Value(0)).current;

	const handleMouseEnter = () => {
		setIsHovered(true);
		animateGlitch();
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	// #region - Functions
	const handleLinkPress = (pageName) => {
		// You can implement navigation logic here to navigate to the desired page
		console.log(`Navigating to ${pageName}`);
		navigation.navigate(pageName);
	};

	const randomTranslateX = Math.floor(Math.random() * 20) - 500; // Random number between -5 and 5
	const randomTranslateY = Math.floor(Math.random() * 20) - 500; // Random number between -5 and 5
	const animateGlitch = () => {
		if (!isHovered) return;

		Animated.sequence([
			Animated.timing(animValue, {
				toValue: 1,
				duration: 50,
				useNativeDriver: false,
			}),
			Animated.timing(animValue, {
				toValue: 0,
				duration: 50,
				useNativeDriver: false,
			}),
		]).start(() => {
			setTimeout(() => {
				animateGlitch();
			}, Math.floor(Math.random() * 1000) + 250);
		});
	};

	const animStyle = {
		opacity: animValue.interpolate({
			inputRange: [0, 0.2, 0.8, 1],
			outputRange: [0, 1, 1, 0],
		}),
		transform: [
			{
				translateX: animValue.interpolate({
					inputRange: [0, 1],
					outputRange: [0, randomTranslateX], // Use the random value
				}),
			},
			{
				translateY: animValue.interpolate({
					inputRange: [0, 1],
					outputRange: [0, randomTranslateY], // Use the random value
				}),
			},
		],
	};

	return (
		<Pressable style={styles.button} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onPress={() => handleLinkPress(pageName)}>
			<View style={{ position: "relative" }}>
				<Text style={styles.buttonText}>{label}</Text>
				<Animated.Text style={[styles.buttonText, styles.buttonTextGlitch1, animStyle]}>{label}</Animated.Text>
				<Animated.Text style={[styles.buttonText, styles.buttonTextGlitch2, animStyle]}>{label}</Animated.Text>
			</View>
		</Pressable>
	);
};

const Home = () => {
	const glitchAnim = useRef(new Animated.Value(0)).current;
	const subHeaderGlitchAnim = useRef(new Animated.Value(0)).current;
	const staticTextAnim = useRef(new Animated.Value(0)).current;

	// #endregion

	useEffect(() => {
		// Header glitch animation logic here
		const animateRandomGlitch = () => {
			const randomDelay = () => Math.floor(Math.random() * 4000) + 1000; // Increased delay for slower frequency
			let animation;
			const rand = Math.random();
			if (rand > 0.66) {
				animation = Animated.sequence([
					Animated.timing(glitchAnim, {
						toValue: 1,
						duration: 50,
						useNativeDriver: false,
					}),
					Animated.timing(glitchAnim, {
						toValue: 0,
						duration: 50,
						useNativeDriver: false,
					}),
				]);
			} else if (rand > 0.33) {
				animation = Animated.sequence([
					Animated.timing(glitchAnim, {
						toValue: 5,
						duration: 30,
						useNativeDriver: false,
					}),
					Animated.timing(glitchAnim, {
						toValue: 0,
						duration: 30,
						useNativeDriver: false,
					}),
				]);
			} else {
				animation = Animated.sequence([
					Animated.timing(glitchAnim, {
						toValue: 2,
						duration: 30,
						useNativeDriver: false,
					}),
					Animated.timing(glitchAnim, {
						toValue: 0,
						duration: 30,
						useNativeDriver: false,
					}),
				]);
			}

			animation.start(() => {
				setTimeout(() => {
					animateRandomGlitch();
				}, randomDelay());
			});
		};

		const animateSubHeaderGlitch = () => {
			const randomDelay = () => Math.floor(Math.random() * 4000) + 1000; // Increased delay for slower frequency
			let animation;
			const rand = Math.random();
			if (rand > 0.66) {
				animation = Animated.sequence([
						Animated.timing(subHeaderGlitchAnim, {
							toValue: 1,
							duration: 50,
							useNativeDriver: false,
						}),
						Animated.timing(subHeaderGlitchAnim, {
							toValue: 0,
							duration: 50,
							useNativeDriver: false,
						}),
					]);
			} else if (rand > 0.33) {
				animation = Animated.sequence([
					Animated.timing(subHeaderGlitchAnim, {
						toValue: 5,
						duration: 30,
						useNativeDriver: false,
					}),
					Animated.timing(subHeaderGlitchAnim, {
						toValue: 0,
						duration: 30,
						useNativeDriver: false,
					}),
				]);
			} else {
				animation = Animated.sequence([
					Animated.timing(subHeaderGlitchAnim, {
						toValue: 2,
						duration: 30,
						useNativeDriver: false,
					}),
					Animated.timing(subHeaderGlitchAnim, {
						toValue: 0,
						duration: 30,
						useNativeDriver: false,
					}),
				]);
			}

			animation.start(() => {
				setTimeout(() => {
					animateSubHeaderGlitch();
				}, randomDelay());
			});
		};

		const animateStaticText = () => {
			const randomDelay = () => Math.floor(Math.random() * 10000) + 2000; // Increased delay for slower frequency
			const randomTranslate = () => Math.floor(Math.random() * 10) - 5;
			let animation = Animated.sequence([
					Animated.timing(staticTextAnim, {
						toValue: 1,
						duration: 50,
						useNativeDriver: false,
					}),
					Animated.timing(staticTextAnim, {
						toValue: 0,
						duration: 50,
						useNativeDriver: false,
					}),
				]);

			animation.start(() => {
				setTimeout(() => {
					animateStaticText();
				}, randomDelay());
			});
		};

		animateRandomGlitch();
		animateSubHeaderGlitch();
		animateStaticText();
	}, []);

	const headerAnimStyle = {
		opacity: glitchAnim.interpolate({
			inputRange: [0, 0.2, 0.8, 1],
			outputRange: [0, 1, 1, 0],
		}),
		transform: [
			{
				translateX: glitchAnim.interpolate({
					inputRange: [0, 1],
					outputRange: [0, 5],
				}),
			},
		],
	};

	const subHeaderAnimStyle = {
		opacity: subHeaderGlitchAnim.interpolate({
			inputRange: [0, 0.2, 0.8, 1],
			outputRange: [0, 1, 1, 0],
		}),
		transform: [
			{
				translateX: subHeaderGlitchAnim.interpolate({
					inputRange: [0, 1],
					outputRange: [0, Math.floor(Math.random() * width) - width / 2],
				}),
			},
			{
				translateY: subHeaderGlitchAnim.interpolate({
					inputRange: [0, 1],
					outputRange: [0, Math.floor(Math.random() * height) - height / 2],
				}),
			},
		],
	};

	const staticTextAnimStyle = {
		transform: [
			{
				translateX: staticTextAnim.interpolate({
					inputRange: [0, 1],
					outputRange: [0, Math.floor(Math.random() * 10) - 5],
				}),
			},
				{
					translateY: staticTextAnim.interpolate({
						inputRange: [0, 1],
						outputRange: [0, Math.floor(Math.random() * 10) - 5],
					}),
				},
			],
		};

	const renderVideo = () => {
		if (Platform.OS === "web") {
			return (
				<video 
					src={videoSource} 
					className="videoBackground" 
					autoPlay 
					loop 
					muted 
					playsInline
				/>
			);
		} else {
			return <></>;
		}
	};


	return (
		<div style={styles.body}>
			<View style={{ position: "absolute", top: 0, zIndex: 1000, backgroundColor: "transparent", }}>
			<Navbar isTransparent={true} />
			</View>
			{renderVideo()}

			<View style={styles.container}>
				<View style={styles.headerContainer}>
					<View style={{ position: "relative", flexDirection: "column", alignItems: "center" }}>
						<Animated.Text style={[styles.header, staticTextAnimStyle]}>MAKENOISE</Animated.Text>
							<View style={{ flexDirection: "row", justifyContent: "center" }}>
								<Animated.Text style={[styles.subHeader, staticTextAnimStyle]}>IMAGINE </Animated.Text>
								<Animated.Text style={[styles.subHeader, staticTextAnimStyle]}>IMPOSSIBLE</Animated.Text>
							</View>
						<Animated.Text style={[styles.header, styles.headerGlitch1, headerAnimStyle]}>MAKENOISE</Animated.Text>
						<Animated.Text style={[styles.header, styles.headerGlitch2, headerAnimStyle]}>MAKENOISE</Animated.Text>
						<Animated.Text style={[styles.subHeader, styles.subHeaderGlitch1, subHeaderAnimStyle]}>IMAGINE</Animated.Text>
						<Animated.Text style={[styles.subHeader, styles.subHeaderGlitch2, subHeaderAnimStyle]}>IMPOSSIBLE</Animated.Text>
					</View>
				</View>
				<View style={styles.buttonContainer}>
				</View>
			</View>
		</div>
	);
};

export default Home;