import React, { useState } from "react";
import { View, TextInput, Text, TouchableOpacity, Picker, StyleSheet } from "react-native";
import styles from "../Styles/SchedulerStyles";
import Navbar from "../Widgets/NavBar";

const Scheduler = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [clientType, setClientType] = useState("new"); // 'new' or 'existing'

	const handleSubmit = () => {
		if (name && email && subject && message) {
			// TODO: Call backend endpoint to send email
			console.log("Form data ready to send...");
		} else {
			console.log("Please fill out all fields.");
		}
	};

	return (
		<View style={styles.body}>
			<View style={{ position: "sticky", top: 0, zIndex: 1000 }}>
				<Navbar />
			</View>
			<View style={styles.container}>
				<Text style={styles.title}>Who we are</Text>
				<Text style={styles.subtitle}>We Power Businesses of the Future, Today</Text>
				<Text style={styles.body}>
					Based out of the vibrant tech hub of Santa Monica, Makenoise Media is not just another software development and business consultation firm. We're a small, dedicated team with an unwavering focus on our clients' success. Our philosophy is simple: when you succeed, we do too. {"\n\n"}
					In a rapidly evolving business landscape, we pride ourselves on our flexibility and innovation. As your tech partner, we're committed to creating solutions that aren't just effective today but remain relevant for the future. {"\n\n"}
					We understand the unique challenges and opportunities your business faces because we take the time to understand you. Our client-centric approach ensures tailored solutions that go beyond just meeting expectations—they set new standards. {"\n\n"}
					Join us in building the businesses of tomorrow, today.
				</Text>
			</View>
		</View>
	);
};

export default Scheduler;
