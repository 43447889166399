import React from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { useNavigation } from "@react-navigation/native";


const Changelogs = () => {
 const navigation = useNavigation();

	return (
		<ScrollView style={styles.container}>
			<View style={styles.textWrapper}>
				<Text style={styles.title}>Changelogs - Lua Browser Extension</Text>
				<Text style={styles.content}>Last Updated: January, 11th 2024{"\n"}</Text>
    <Text style={styles.content} onPress={() => navigation.navigate("Blog")}>Download Lua Here{"\n\n"}</Text> 		

				<View>
					<Text style={styles.currentVersionHeader}>Current Version - 1.1.0</Text>
					<Text style={styles.currentVersion}>- Fixed Tooltip (Top of Page) Cutoff{"\n"}</Text>
					<Text style={styles.currentVersion}>- Fixed Translation Error Insertion{"\n"}</Text>
					<Text style={styles.currentVersion}>- Error message is no longer inserted as the translated text{"\n"}</Text>
					<Text style={styles.currentVersion}>- Fixed [Object Object] Insertion{"\n"}</Text>
					<Text style={styles.currentVersion}>- [Object Object] is no longer inserted as the translated text{"\n"}</Text>
					<Text style={styles.currentVersion}>- Popup UI Updates{"\n"}</Text>
					<Text style={styles.currentVersion}>- Added Home Tab{"\n"}</Text>
					<Text style={styles.currentVersion}>- Added Settings Tab{"\n"}</Text>
					<Text style={styles.currentVersion}>- Added Color Picker{"\n"}</Text>
					<Text style={styles.currentVersion}>- Added Default Option{"\n"}</Text>
					<Text style={styles.currentVersion}>- Added Seamless Option{"\n"}</Text>
					<Text style={styles.currentVersion}>- Added Enable/Disable Extension Button{"\n"}</Text>
					<Text style={styles.currentVersion}>- Updated Beginner and Intermediate Proficiency Levels{"\n"}</Text>
					<Text style={styles.currentVersion}>- Fewer words on the page show for each level{"\n"}</Text>
					<Text style={styles.currentVersion}>- Added In-App Updater + Version Checker{"\n"}</Text>
					<Text style={styles.currentVersion}>- More reliable translations{"\n"}</Text>
					<Text style={styles.currentVersion}>- Background Tab Updating{"\n"}</Text>
					<Text style={styles.currentVersion}>- Updated Tab Tracking{"\n"}</Text>
					<Text style={styles.currentVersion}>- Added More Translation Eligible Elements Tags (span, li, ul, a){"\n"}</Text>
					<Text style={styles.currentVersion}>- Can now translate links (a tags){"\n"}</Text>
					<Text style={styles.currentVersion}>- Added Reconnection Logic (for erroneous/temporary disconnects){"\n"}</Text>
					<Text style={styles.currentVersion}>- Updated Error Handling{"\n"}</Text>
					<Text style={styles.currentVersion}>- Added Translation Toggle Button{"\n"}</Text>
					<Text style={styles.currentVersion}>- Punctuation retention fixes{"\n"}</Text>
					<Text style={styles.currentVersion}>- Bug Fixes{"\n"}</Text>
				</View>

    <View style={styles.version}>
					<Text style={styles.prevVersionHeader}>Version - 1.0</Text>
					<Text style={styles.prevVersion}>Lua Alpha Launch</Text>
				</View>
			</View>

			<Text style={styles.bottomText}>Thank you for using Lua!</Text>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
		backgroundColor: "rgb(15, 15, 15)",
	},
	textWrapper: {
		paddingHorizontal: 30,
		paddingBottom: 25,
  textAlign: "center",
	},
	title: {
		fontFamily: "Montserrat",
		fontSize: 28,
		fontWeight: "bold",
		marginBottom: 10,
		color: "white",
		paddingTop: 15,
  textAlign: "center",
	},
	content: {
		fontFamily: "Khmer MN",
		fontSize: 22,
		color: "#ffffff",
		lineHeight: 38,
		textAlign: "center",
	},
	version: {
		paddingVertical: 25,
	},
 currentVersionHeader: {
		fontFamily: "Khmer MN",
		fontSize: 22,
		color: "green",
		lineHeight: 38,
		textAlign: "center",
	},
	currentVersion: {
		fontFamily: "Khmer MN",
		fontSize: 22,
		color: "green",
		lineHeight: 38,
		textAlign: "center",
	},
 prevVersionHeader: {
		fontFamily: "Khmer MN",
		fontSize: 22,
		color: "grey",
		lineHeight: 38,
		textAlign: "center",
	},
	prevVersion: {
		fontFamily: "Khmer MN",
		fontSize: 22,
		color: "grey",
		lineHeight: 38,
		textAlign: "center",
	},
	bottomText: {
		textAlign: "center",
		fontFamily: "Khmer MN",
		fontSize: 22,
		color: "#ffffff",
		lineHeight: 38,

		paddingVertical: 25,
	},
});

export default Changelogs;
