import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Home from "../Screens/Home";
import Scheduler from "../Screens/Scheduler";
import About from "../Screens/About";
import Contact from "../Screens/Contact";
import Blog from "../Screens/Blog";
import PrivacyPolicy from "../Screens/PrivacyPolicy";
import Changelogs from "../Screens/Changelogs";

const linking = {
	prefixes: ["https://makenoisemedia.io", "makenoisemedia://", "http://localhost:"],
	config: {
		screens: {
			Home: "",
			Scheduler: "meetings",
			Profile: "profile",
			About: "about",
			Blog: "blog",
			Contact: "contact",
			Privacy: "privacy", // Add this line
			Changelogs: "changelogs",
		},
	},
};

const Stack = createStackNavigator();

const Navigation = () => {
	return (
		<NavigationContainer linking={linking}>
			<Stack.Navigator initialRouteName="Home">
				<Stack.Screen
					name="Home"
					component={Home}
					options={{
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="Scheduler"
					component={Scheduler}
					options={{
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="About"
					component={About}
					options={{
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="Blog"
					component={Blog}
					options={{
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="Contact"
					component={Contact}
					options={{
						headerShown: false,
					}}
				/>
				<Stack.Screen name="Privacy" component={PrivacyPolicy} options={{ headerShown: false }} />

				<Stack.Screen name="Changelogs" component={Changelogs} options={{ headerShown: false }} />
			</Stack.Navigator>
		</NavigationContainer>
	);
};

export default Navigation;
