
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { FIREBASE_URL } from "@env";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: `${FIREBASE_URL}`,
  authDomain: "lua-chrome-extension.firebaseapp.com",
  projectId: "lua-chrome-extension",
  storageBucket: "lua-chrome-extension.appspot.com",
  messagingSenderId: "160271895753",
  appId: "1:160271895753:web:2c5b0c9c5f13546558d118",
  measurementId: "G-QLT640N422"
};

export default firebaseConfig

