import React from "react";
import { View, Text, Image, StyleSheet, Pressable, Dimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";

const { width } = Dimensions.get("window");
const isSmallDevice = width < 400;
const isTablet = width < 1024 && width > 400;
let transparent;

const Navbar = ({ isTransparent }) => {
	const navigation = useNavigation();

	transparent = isTransparent;

	return (
		<View
			style={{
				backgroundColor: isTransparent ? "transparent" : "rgb(15, 15, 15)",
				width: "100vw",
				height: 65,
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				paddingHorizontal: isSmallDevice ? 15 : isTablet ? 25 : 50,
				paddingVertical: 50,
				elevation: 10,
				zIndex: 1000,
			}}>
			<View
				style={{
					flexDirection: "row",
					alignItems: "center",
					backgroundColor: isTransparent ? "transparent" : "rgb(15, 15, 15)",
				}}>
				<Pressable onPress={() => navigation.navigate("Home")}>
					<Text style={styles.navText}>Home</Text>
				</Pressable>

				{/* <Pressable onPress={() => navigation.navigate("Scheduler")}>
					<Text style={styles.navText}>Meeting</Text>
				</Pressable> */}

				<Pressable onPress={() => navigation.navigate("About")}>
					<Text style={styles.navText}>About Us</Text>
				</Pressable>

				<Pressable onPress={() => navigation.navigate("Blog")}>
					<Text style={styles.navText}>Blog</Text>
				</Pressable>

				<Pressable onPress={() => navigation.navigate("Contact")}>
					<Text style={styles.navText}>Contact</Text>
				</Pressable>
			</View>

			<Pressable onPress={() => navigation.navigate("Home")}>
				<Image
					source={require("../assets/icon.png")}
					style={styles.logo}
					resizeMode="contain" // Try adding this resizeMode property
				/>
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	navText: {
		fontFamily: "Khmer MN",
		fontWeight: "500",
		fontSize: isSmallDevice ? 15 : isTablet ? 15 : 30,
		textAlign: "left",
		color: "#f7f7e0",
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 6,
		marginLeft: 30,
	},

	logo: {
		width: isSmallDevice ? 30 : isTablet ? 55 : 110,
		height: isSmallDevice ? 30 : isTablet ? 55 : 110, // Change the height to 100% to fit within the navbar
		transform: [{ scale: 0.75 }],
	},
});

export default Navbar;
