import React, { useState, useEffect } from "react";
import { View, Text, TextInput, ImageBackground, Pressable, Button, Linking, Image, Modal, TouchableOpacity, Dimensions } from "react-native";
import Navbar from "../Widgets/NavBar";
import { useNavigation } from "@react-navigation/native";
import styles from "../Styles/BlogStyles";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, doc, runTransaction } from "firebase/firestore";
import firebaseConfig from "../Widgets/Firebase";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
let showingDownload = false;

const { width } = Dimensions.get("window");
const isSmallDevice = width < 400;
const isTablet = width < 1024 && width > 400;
const isUnsupportedDevice = isTablet || isSmallDevice;

// Email Capture Component
const EmailCapture = ({ onClose, downloadClicked }) => {
	const [email, setEmail] = useState("");
	const [Fname, setFName] = useState("");
	const [Lname, setLName] = useState("");

	const handleSubmit = async () => {
		// Validate name and email
		if (!Fname.trim() || !Lname.trim()) {
			alert("Please enter your first and last name.");
			return;
		}

		// Regular expression for email validation
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (!emailRegex.test(email)) {
			alert("Please enter a valid email address.");
			return;
		}

		try {
			await addDoc(collection(db, "website-users"), {
				firstName: Fname,
				lastName: Lname,
				email: email,
				signedUpAfterDownload: downloadClicked, // Add this field to your Firestore document
			});

			const thankYouMessage = downloadClicked ? "Thank you for downloading LUA!" : `Thank you for signing up!`;
			alert(thankYouMessage);
			onClose();
		} catch (error) {
			console.error("Error adding document: ", error);
			alert("An error occurred while signing up. Please try again.");
		}
	};

	return (
		<div style={styles.emailCaptureOverlay}>
			<div style={styles.emailCaptureContainer}>
				<Text style={styles.emailCaptureText}>Join the Lua Community!{"\n"}</Text>
				<Text style={styles.emailCaptureSubText}>Sign up to stay updated with the latest developments in Lua. Be the first to know about new features, updates, and exclusive insights. Your feedback and suggestions are valuable to us – help us make Lua even better!</Text>
				<View style={styles.row}>
					<TextInput style={styles.input} onChangeText={setFName} value={Fname} placeholder="First Name" />
					<TextInput style={styles.input} onChangeText={setLName} value={Lname} placeholder="Last Name" />
				</View>
				<TextInput style={styles.input} onChangeText={setEmail} value={email} placeholder="Email" keyboardType="email-address" />
				<TouchableOpacity title="Download Now" onPress={handleSubmit} style={styles.emailSignUpButton}>
					<Text style={styles.emailSignUpButtonText}>Subscribe</Text>
				</TouchableOpacity>
				<TouchableOpacity onPress={onClose} style={styles.closeButtonEmail}>
					<Text style={styles.closeButtonText}>Close</Text>
				</TouchableOpacity>
			</div>
		</div>
	);
};

// Download Lua Function
const DownloadLua = ({ onClose, setShowEmailCapture, setDownloadClicked }) => {
	// Function to handle downloading a PDF file
	const handleZipDownload = async (pdfUrl) => {
		if (!showingDownload) {
			return;
		}

		// Firestore document reference
		const downloadsDocRef = doc(db, "website-stats", "stats");

try {
    const response = await fetch(pdfUrl, { cache: "no-cache" });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const blob = await response.blob();
    alert(`Downloaded file size: ${blob.size} bytes`);

			const url = window.URL.createObjectURL(blob);

			// Create a new anchor element
			const a = document.createElement("a");
			a.href = url;
			a.download = pdfUrl.split("/").pop(); // Set the file name
			document.body.appendChild(a);
			a.click();

			// Clean up
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);

			setShowEmailCapture(true);
			setDownloadClicked(true);

			try {
				await runTransaction(db, async (transaction) => {
					const downloadsDoc = await transaction.get(downloadsDocRef);
					if (!downloadsDoc.exists()) {
						throw "Document does not exist!";
					}

					let count = downloadsDoc.data().downloads;
					let newCount = count + 1;
					transaction.update(downloadsDocRef, { downloads: newCount });
				});

				console.log("Download count incremented.");
			} catch (e) {
				console.error("Transaction failed: ", e);
			}
		} catch (error) {
			console.error("Download error:", error.message);
		}
	};

	if (!isUnsupportedDevice) {
		return (
			<View style={styles.modalBackground}>
				<View style={styles.downloadLuaContainer}>
					<Image source={require("../assets/luaicon.png")} style={styles.logoDL} />
					<Text style={styles.downloadLuaTitle}>Welcome to the Lua Chrome Extension</Text>
					<Text style={styles.downloadLuaSubText}>Unlock the world with Lua!</Text>
					<Text style={styles.downloadLuaTextVersion}>Lua Version 1.1.0</Text>

					<Text style={styles.downloadLuaText}>Installation Instructions:</Text>
					<Text style={styles.downloadLuaText}>
						1. Download the extension using the button below or by clicking{" "}
						<Pressable
							style={styles.downloadLuaTextVersion}
							onPress={() => {
								showingDownload = true;
								handleZipDownload("../Lua.zip");
							}}>
							<Text>here.</Text>
						</Pressable>
					</Text>
					<Text style={styles.downloadLuaText}>
						2. Open Google Chrome and go to <Text onPress={() => Linking.openURL("chrome://extensions/")}>chrome://extensions/</Text>.
					</Text>
					<Text style={styles.downloadLuaText}>3. Enable Developer Mode in the top right of the page.</Text>
					<Text style={styles.downloadLuaText}>4. Click "Load unpacked" in the top left of the page.</Text>
					<Text style={styles.downloadLuaText}>5. Select the downloaded file. (Be sure to unzip the file by double clicking it first!)</Text>
					<Text style={styles.downloadLuaText}>6. Enjoy learning with Lua!</Text>

					<TouchableOpacity
						title="Download Now"
						onPress={() => {
							showingDownload = true;
							handleZipDownload("../Lua.zip");
						}}
						style={styles.downloadButton}>
						<Text style={styles.downloadButtonText}>Download Lua</Text>
					</TouchableOpacity>

					<TouchableOpacity
						title="Close"
						onPress={() => {
							showingDownload = false;
							onClose();
						}}
						style={styles.closeButton}>
						<Text style={styles.closeButtonText}>Close</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	} else {
		return (
			<View style={styles.modalBackground}>
				<View style={styles.downloadLuaContainer}>
					<Text style={styles.downloadLuaTitle}>LUA Availability</Text>
					<Text style={styles.downloadLuaSubText}>Lua is currently only available on computers. Please visit this page on a desktop or laptop to download Lua. Thanks! :)</Text>
					<TouchableOpacity title="Close" onPress={onClose} style={styles.closeButton}>
						<Text style={styles.closeButtonText}>Close</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	}
};

// LUA Blog Post Component
const LUABlogPost = () => {
	const [showDownloadPopup, setShowDownloadPopup] = useState(false);
	const [showEmailCapture, setShowEmailCapture] = useState(false);
	const [downloadClicked, setDownloadClicked] = useState(false);

	const navigation = useNavigation();

	const handleBuyCoffeeClick = () => {
		// Placeholder for Stripe logic
		const donationURL = "https://donate.stripe.com/cN28xzdRJ16e7eg5kp";
		Linking.openURL(donationURL);
	};

	const handleCloseEmailCapture = () => {
		setShowEmailCapture(false);
	};

	const incrementPageVisits = async () => {
		// Firestore document reference
		const downloadsDocRef = doc(db, "website-stats", "stats");

		try {
			await runTransaction(db, async (transaction) => {
				const downloadsDoc = await transaction.get(downloadsDocRef);
				if (!downloadsDoc.exists()) {
					throw "Document does not exist!";
				}

				let count = downloadsDoc.data().blogPageVisits;
				let newCount = count + 1;
				transaction.update(downloadsDocRef, { blogPageVisits: newCount });
			});

			console.log("Download count incremented.");
		} catch (e) {
			console.error("Transaction failed: ", e);
		}
	};

	useEffect(() => {
		// Check if the modal has been shown before
		const hasVisited = localStorage.getItem("hasVisited");
		if (!hasVisited) {
			setShowEmailCapture(true);
			localStorage.setItem("hasVisited", "true");
			incrementPageVisits();
		}
	}, []);

	return (
		<View style={styles.container}>
			<View style={styles.header}>
				<View style={styles.subheader}>
					<Text style={styles.title}>Unlock the World with LUA: Your Personal Language Learning Companion!</Text>
					<Text style={styles.subtitle}>Imagine seamlessly picking up a new language as you browse the internet. That's the magic of LUA, the Chrome extension that's changing the game in language learning!</Text>
					<Text style={styles.dateline}>March, 11th 2024 - 6:06 PM</Text>
					<Text style={styles.changelogs} onPress={() => navigation.navigate("Changelogs")}>
						See Updates
					</Text>

					<View style={styles.row}>
						<Pressable style={styles.getLuaButton} onPress={() => setShowDownloadPopup(true)}>
							<Text style={styles.signUpButtonText}>Download Lua Here</Text>
						</Pressable>
						<Pressable style={styles.coffeeButton} onPress={() => handleBuyCoffeeClick()}>
							<Text style={styles.signUpButtonText}>Buy Me a Coffee :)</Text>
						</Pressable>
					</View>
				</View>

				<Image source={require("../assets/luaicon.png")} style={styles.logo} />
			</View>

			<View style={styles.separator} />
			<div style={styles.container}>
				<img src={require("../assets/Lua7.jpg")} style={styles.exImgL} />
				<p style={styles.blogTextPR}>✨ With LUA, you're not just reading; you're absorbing. It transforms your everyday web journey into a dynamic language learning experience. Words and phrases in your target language gently blend into your reading, enhancing your vocabulary and comprehension without overwhelming you. Choose from over 250 languages (or accents like Pirate-Speak, Arg Matey!), to integrate your target languages in your day to day life. Hover over words and phrases to reveal the original text, to help you test your knowledge. Change your proficiency whenever you're ready to put you language skills up to a challenge, or simply when you feel like you've progressed to the next level. {"\n\n"}</p>

				<p style={styles.blogTextPL}>
					🧠 The Science Behind Our Method:{" "}
					<a style={{ color: "blue" }} onClick={() => Linking.openURL("https://www.cambridge.org/core/journals/language-teaching/article/how-effective-is-second-language-incidental-vocabulary-learning-a-metaanalysis/E38E3468FD2090B1FA3051051DE8E70C#:~:text=Studies%20of%20incidental%20vocabulary%20learning%20through%20reading%20have%20reported%20the,Webb%20%26%20Chang%2C%20Reference%20Webb%20and")}>
						Cognitive research
					</a>{" "}
					<img src={require("../assets/Airbnb Example.jpg")} style={styles.exImgR} />
					suggests that language acquisition is most effective when it's integrated into our daily activities. This 'incidental learning' approach, where new words are learned in context, is exactly what LUA leverages. By seamlessly integrating new languages into your regular web browsing, LUA taps into your brain's natural language processing abilities. Studies have shown that learning in context dramatically improves both retention and comprehension, making LUA not just innovative, but also scientifically grounded.{"\n\n"}
					🚀 Whether you're taking baby steps in a new language or polishing your advanced skills, LUA is your adaptable partner, intuitively aligning with your pace. It's like having a language coach who's always on call, ready to nudge you with new linguistic gems as you read.{"\n\n"}
					🤖 AI-Powered Learning in Today's World: In an era where AI is revolutionizing how we learn, LUA stands out by incorporating advanced algorithms. These algorithms personalize your learning experience, ensuring that the difficulty and frequency of new words are perfectly matched to your level. Traditional language learning methods often follow a one-size-fits-all approach. LUA, on the other hand, understands that everyone's learning curve is different, and adapts accordingly.{"\n\n"}
				</p>

				<img src={require("../assets/Lua6.jpg")} style={styles.exImgL} />
				<p style={styles.blogTextPL}>
					👆 Soon, you'll be able to hover over a word and get instant insights – definitions, pronunciations, and more. LUA is crafting a future where language learning is as interactive as it is intuitive.{"\n\n"}
					💼 Active Learning with LUA: The upcoming feature, Lua Penpal, revolutionizes this further. Imagine having an AI language coach that you can converse with, applying the words and phrases you've learned directly. This active learning approach, where you're not just passively receiving information but actively using it, has been shown to accelerate language acquisition. By simulating real-life conversations, Lua Penpal will prepare you for real-world language use like never before.{"\n\n"}
					📢 Stay in the Loop: Don't miss out on LUA's evolution! Follow us for the hottest updates, savvy learning hacks, and a peek into how LUA is making language learning accessible to everyone, everywhere.{"\n\n"}
					Ready to make language learning a part of your daily web adventure?{"\n\n"}
					Keep your eyes peeled for LUA, where languages unfold one click at a time!{"\n\n"}
				</p>

				<p style={styles.blogTextPL}>
					🌐 Looking Forward: The exciting development of Lua Penpal promises to add an extra layer of immersion to your learning experience. It's not just about reading and understanding; it's about interacting and engaging. Lua Penpal, acting as your AI language coach, will offer you a platform to practice conversations, get immediate feedback, and fine-tune your language skills in real-time.{"\n\n"}
					Join us on this exciting ride and watch as words turn into worlds!{"\n\n"}
				</p>
				<img src={require("../assets/Lua4.jpg")} style={styles.exImg} />
			</div>

			<Text style={styles.sourceLink}>
				For more on the science behind our method, visit{"\n"}
				<Text style={{ color: "blue" }} onPress={() => Linking.openURL("https://www.jstor.org/stable/1422811")}>
					Feature-Positive Effect in Adults{"\n"}
				</Text>
				<Text style={{ color: "blue" }} onPress={() => Linking.openURL("https://www.cambridge.org/core/journals/language-teaching/article/how-effective-is-second-language-incidental-vocabulary-learning-a-metaanalysis/E38E3468FD2090B1FA3051051DE8E70C#:~:text=Studies%20of%20incidental%20vocabulary%20learning%20through%20reading%20have%20reported%20the,Webb%20%26%20Chang%2C%20Reference%20Webb%20and")}>
					Cambridge - incidental vocabulary learning.
				</Text>
			</Text>
			<Text style={styles.privacy} onPress={() => navigation.navigate("Privacy")}>
				See Privacy Policy
			</Text>

			{/* Show Email Capture Popup */}
			{showEmailCapture && <EmailCapture onClose={handleCloseEmailCapture} downloadClicked={downloadClicked} />}

			{/* Show Download Page */}
			<Modal visible={showDownloadPopup} onRequestClose={() => setShowDownloadPopup(false)}>
				<DownloadLua onClose={() => setShowDownloadPopup(false)} setShowEmailCapture={setShowEmailCapture} setDownloadClicked={setDownloadClicked} />
			</Modal>
		</View>
	);
};

const Blog = () => {
	const navigation = useNavigation();

	const [isModalVisible, setModalVisible] = useState(false);

	const { width } = Dimensions.get("window");
	const isSmallDevice = width < 400;
	const isTablet = width < 1800 && width > 400;

	const toggleModal = () => {
		setModalVisible(!isModalVisible);
	};

	return (
		<View style={styles.body}>
			{/* Hamburger Menu */}
			{isSmallDevice ||
				(isTablet && (
					<TouchableOpacity onPress={toggleModal} style={styles.hamburgerMenu}>
						<Text>☰</Text> {/* You can replace this with an icon */}
					</TouchableOpacity>
				))}

			{/* Navigation Modal */}
			<Modal animationType="slide" transparent={true} visible={isModalVisible} onRequestClose={toggleModal}>
				<View style={styles.modalBackground}>
					{/* Your navigation links here */}
					{/* Close Button */}
					<TouchableOpacity onPress={toggleModal}>
						<Text>Close</Text>
					</TouchableOpacity>
				</View>
			</Modal>

			<View style={{ position: "sticky", top: 0, zIndex: 1000 }}>
				<Navbar />
			</View>
			<LUABlogPost />
		</View>
	);
};

export default Blog;
